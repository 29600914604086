<template>
    <b-modal 
        ref="cookiemsg"
        @ok="setCookie"
        title="Diese Website verwendet Cookies"
        okTitle="Cookies zulassen"
        cancelTitle="Keine Cookies zulassen"
        size="md"
        centered
        no-stacking
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
    >
        <div class="text-center">
            Wir verwenden Cookies von<br>
            <b>Discord, Microsoft Forms</b> und <b>Youtube</b><br>
            für diese Webseite.
            Falls du Cookies deaktivieren möchtest, funktionieren
            einige Inhalte der Seite leider nicht mehr.
        </div>
    </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
export default {
    name: "CookieModal",
    components: { BModal },
    methods: {
        setCookie() {
            var d = new Date();
            d.setTime(d.getTime() + (7*24*60*60*1000));
            document.cookie = `bsz-virtual-infoday=true;expires=${d.toUTCString()};path=/`;
            this.$root.allowCookies = true;
            this.$emit('open-discord');
        }
    },
    mounted() {
        var cookies = document.cookie.split('; ');
        var cookieAllowed = false;
        for(var x of cookies) {
            var cookie = x.split('=');
            if(cookie[0] == "bsz-virtual-infoday") {
                cookieAllowed = cookie[1];
            }
        }
        if(!cookieAllowed) {
            this.$refs['cookiemsg'].show();
        } else {
            this.$root.allowCookies = true;
        }
    }
}
</script>