<template>
    <div>
        <transition name="slide-fade">
            <div 
                v-show="shown" 
                class="popup"
                style="z-index:50" 
            >
                <cookie-overlay href="https://discord.com/invite/6MFcswhb36">
                    <discord 
                        v-if="$root.allowCookies" 
                        @click.prevent 
                        style="height:60vh"
                    />
                    <div 
                        v-else 
                        style="height:60vh;background-color:darkgrey"
                    />
                </cookie-overlay>
            </div>
        </transition>
        <div 
            class="discord-widget-label" 
            @click="shown = !shown"
        >
            <img 
                src="@/assets/icons/discord.svg" 
                :class="shown ? 'discord-widget-img-active' : 'discord-widget-img'"
            />
        </div>
    </div>
</template>

<script>
import Discord from '@/components/Discord';
import CookieOverlay from '@/components/CookieOverlay';
export default {
    name: "DiscordWidget",
    components: { Discord, CookieOverlay },
    computed: {
        shown: {
            get() { 
                return this.shownfield;
            },
            set(value) {
                if(value) {
                    setTimeout(() => window.addEventListener('click', this.hide), 100);
                } else {
                    window.removeEventListener('click', this.hide);
                }
                this.shownfield = value;
            }
        }
    },
    data: () => ({
        shownfield: false
    }),
    methods: {
        show() {
            if(this.shown == false) {
                this.shown = true;
            }
        },
        hide() {
            if(this.shown == true) {
                this.shown = false;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('click', this.hide);
    }
}
</script>

<style scoped>
    .popup{
        max-width: 18em;
        width: 90vw;
        height: 60vh;
        position: fixed !important;
        bottom: 7.2em;
        right: 1.5em;
    }
    .discord-widget-label{
        width: 5em;
        height: 5em;
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 0 1em 1em 0;
        box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
        border-radius: 54em;
        z-index: 500;
    }
    .discord-widget-img{
        position: absolute;
        top: -6%;
        left: -6%;
        width: 112%;
        filter: opacity(.9) brightness(.8);
        border-radius: 54em;
        cursor: pointer;
    }
    .discord-widget-img:hover{
        filter: saturate(1.5) brightness(.9);
    }
    .discord-widget-img:active{
        filter: contrast(1.2) saturate(1.5) brightness(.9);
    }
    .discord-widget-img-active{
        position: absolute;
        top: -6%;
        left: -6%;
        width: 112%;
        filter: contrast(1.2) saturate(1.5) brightness(.9);
        border-radius: 54em;
        cursor: pointer;
    }
    .slide-fade-enter-active {
        transition: all .2s ease-in;
    }
    .slide-fade-leave-active {
        transition: all .2s ease-out;
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(40px) translateY(20px);
        opacity: 0;
    }
</style>