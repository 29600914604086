<template>
    <footer class="footer">
        <div class="mx-5">
            <hr class="mb-n2">
            <span class="text-muted">
                Copyright (C) BSZ Wiesau 2021 - 
                <router-link to="/impressum">
                    Impressum
                </router-link>
            </span>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Foot"
}
</script>

<style scoped>
    .footer {
        margin-top: 20px;
        width: 100%;
        height: 60px;
        line-height: 60px;
    }
</style>
