import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { VBToggle } from 'bootstrap-vue'
Vue.directive('b-toggle', VBToggle)

Vue.config.productionTip = false

new Vue({
    data(){return{ allowCookies: false }},
    router,
    render: h => h(App)
}).$mount('#app')
