<template>
    <iframe 
        :src=widgetsrc 
        width="100%" 
        height="500" 
        allowtransparency="true" 
        frameborder="0" 
        :sandbox="$root.allowCookies ? 'allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts' : ''"
    />
</template>

<script>
export default {
    name: "Discord",
    data: () => ({
        widgetsrc: "https://discord.com/widget?id=771060943807447101&theme=dark"
    }),
}
</script>