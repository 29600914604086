<template>
<div>
    <slideshow style="min-height: 3em; margin-bottom: -3em"/>
    <div class="container d-flex flex-column">
        <welcome
            class="my-4"
            @open-discord="$emit('open-discord')"
        />
    </div>
</div>
</template>

<script>
import Welcome from "@/components/Welcome"
import Slideshow from '../components/Slideshow.vue'
export default {
    name: 'Home',
    components: { Welcome, Slideshow }
}
</script>