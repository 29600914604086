<template>
    <div id="app">
        <div 
            class="maincontent"
            :style="mainStyle"
        >
            <navbar/>
            <transition 
                :name="transitionName" 
                mode="out-in"
                @before-enter="mainStyle = 'overflow: hidden'"
                @before-leave="mainStyle = 'overflow: hidden'"
                @after-enter="mainStyle = ''"
                @after-leave="mainStyle = ''"
                @enter-cancelled="mainStyle = ''"
                @leave-cancelled="mainStyle = ''"
            >
                <router-view @open-discord="openDiscord"/>
            </transition>
            <discord-widget ref="discord"/>
            <cookie-modal @open-discord="openDiscord"/>
        </div>
        <foot/>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import CookieModal from '@/components/CookieModal'
import DiscordWidget from '@/components/DiscordWidget'
import Foot from '@/components/Foot'

export default {
    components: { Navbar, CookieModal, DiscordWidget, Foot },
    methods: {
        openDiscord() {
            this.$refs['discord'].show();
        }
    },
    data: () => ({
        transitionName: 'slide-right',
        mainStyle: ''
    }),
    watch: {
        '$route' (to, from) {
            var toIndex = this.$router.options.routes.findIndex(r => to.path == r.path);
            var fromIndex = this.$router.options.routes.findIndex(r => from.path == r.path);
            if(toIndex > fromIndex) {
                this.transitionName = 'slide-left';
            } else {
                this.transitionName = 'slide-right';
            }
        }
    }
}
</script>

<style scoped>
    .maincontent {
    min-height: calc(100vh - 80px);
    padding-bottom: 80px;
    }

    footer {
    bottom: 0;
    width: 100%;
    height: 60px;
    }
    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: 0.2s;
        transition-property: opacity, transform;
        transition-timing-function:ease-in-out;
        overflow-x: hidden;
    }
    .slide-left-enter,
    .slide-right-leave-active {
        opacity: 0;
        transform: translate(50%, 0);
    }
    .slide-left-leave-active,
    .slide-right-enter {
        opacity: 0;
        transform: translate(-50%, 0);
    }
</style>

<style>
    .btn-info{
        background-color: #006CA1 !important;
        border-color: #0a618dc5 !important;
        font-size: 1.05em;
        padding: .5em 0;
    }
</style>