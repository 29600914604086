<template>
    <b-overlay :show="!$root.allowCookies">
        <template #overlay>
            <p>
                Aktiviere Cookies, um diesen Inhalt sehen zu können.
                <br>
                Klicke
                <a :href="href" target="_blank">hier</a>,
                um diesen Inhalt in einem neuen Fenster zu öffnen.
            </p>
        </template>
        <slot/>
    </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
export default {
    name: "CookieOverlay",
    components: { BOverlay },
    props: {
        href: String
    }
}
</script>