<template>
    <b-navbar
        toggleable="lg"
        type="dark"
        variant="dark"
    >
        <b-navbar-brand
            to="/"
            class="brando d-flex flex-row"
        >
            <img 
                src="@/assets/logo.svg"
                height="40em"
                class="mr-1"
                style="margin-top:-0.1em"
            />
            <div>
                <p id="nav-brand-top">Informatik-Campus Wiesau</p>
                <p id="nav-brand-bottom">Virtuelle Infotage</p>
            </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"/>

        <b-collapse
            id="nav-collapse"
            is-nav
        >
            <b-navbar-nav
                class="w-100"
                align="start"
                fill
            >
                <b-nav-item 
                    v-for="route in routes"
                    :key="route.name"
                    :to="route.path" 
                    exact exact-active-class="active"
                >
                    <img
                        :src="route.meta.icon"
                        height="18em"
                        class="mt-n1 info-icon-nav"
                    />
                    {{route.name}}
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import { BNavbar, BNavbarBrand, BNavbarToggle, BNavbarNav, BNavItem, BCollapse } from "bootstrap-vue"
export default {
    name: "Navbar",
    components: { BNavbar, BNavbarBrand, BNavbarToggle, BNavbarNav, BNavItem, BCollapse },
    computed: {
        routes: function(){
            return this.$router.options.routes.filter(r => r.meta.visible == undefined );
        }
    }
}
</script>
<style scoped>
    @media(max-width: 345px){
        .brando{
            margin-right: -.5em;
            margin-left: -.5em;
        }
    }
    .info-icon-nav{
        opacity: 0.5;
        filter: invert();
    }
    .nav-link{
        border-radius: .3em !important;
        padding-bottom: .7em !important;
        margin-right: .5em !important;
    }
    .navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
        color: #a0cae0 !important;
        background-color: #0075af33;
    }
    .navbar-dark .navbar-nav .nav-link:hover {
        background-color: #1b2d3633;
    }
    #nav-brand-bottom{
        color: #a0cae0;
        font-size: 1.3em;
        margin: -0.5em -3em -0.2em 0em;
    }
    #nav-brand-top{
        color: #688392;
        font-size: 0.81em;
        margin: 0em;
        margin-top: -0.4em;
        margin-bottom: 0.1em
    }
</style>

