import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { icon: require('@/assets/icons/home.svg')}
    },
    {
        path: '/discord',
        name: 'Discord',
        component: () => import('../views/DiscordManual.vue'),
        meta: { icon: require('@/assets/icons/discord2.svg')}
    },
    {
        path: '/videos',
        name: 'Videos',
        component: () => import('../views/Videos.vue'),
        meta: { icon: require('@/assets/icons/video-camera.svg')}
    },
    {
        path: '/berichte',
        name: 'Berichte',
        component: () => import('../views/Berichte.vue'),
        meta: { icon: require('@/assets/icons/copy.svg')}
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import('../views/Faq.vue'),
        meta: { icon: require('@/assets/icons/help.svg')}
    },
    {
        path: '/Quiz',
        name: 'Quiz',
        component: () => import('../views/Quiz.vue'),
        meta: { icon: require('@/assets/icons/lightbulb.svg')}
    },
    {
        path: '/visit',
        name: 'Besuchen',
        component: () => import('../views/Visit.vue'),
        meta: { icon: require('@/assets/icons/building.svg')}
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: () => import('../views/Impressum.vue'),
        meta: { icon: require('@/assets/icons/information.svg'), visible: false}
    }
]

const router = new VueRouter({
    routes
})

export default router