<template>
    <b-aspect aspect="128:41">
        <b-carousel
            :value="Math.floor(Math.random() * 6)"
            :interval="6000"
            controls
            background="#3a6b9e"
        >
            <b-carousel-slide
                v-for="slide in slides"
                :key="slide"
                :img-src="require(`@/assets/slideshow/slide${slide}.jpg`)"
            />
        </b-carousel>
    </b-aspect>
</template>

<script>
import { BCarousel, BCarouselSlide, BAspect } from 'bootstrap-vue'
export default {
    name: "Slideshow",
    components: { BCarousel, BCarouselSlide, BAspect },
    data: () => ({
        slides: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    })
}
</script>