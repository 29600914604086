<template>
    <div>
        <b-container>
            <b-row cols-lg="2" cols="1">
                <b-col>
                    <b-card class="m-1 shadow">
                        <template #header>
                            <h4 class="mb-0">Willkommen</h4>
                        </template>
                        Willkommen auf der Homepage zu den 
                        virtuellen Infotagen 2021 des 
                        Informatik-Campus Wiesau.<br>
                        Die SMV hat keine Mühen gescheut euch 
                        trotz der aktuellen Lage zu ermöglichen,
                        uns in unserem Schulalltag ein bisschen 
                        über die Schulter zu schauen.
                    </b-card>
                </b-col>

                <b-col>
                    <b-card class="m-1 shadow">
                        <template #header>
                            <h4 class="mb-0">Inhalte</h4>
                        </template>
                        Auf dieser Webseite findet ihr viele Inhalte.<br>
                        Wir haben 
                        <b-button size="sm" 
                            variant="outline-primary" 
                            href="#/videos">
                            Videos
                        </b-button>, 
                        <b-button size="sm" 
                            variant="outline-primary" 
                            href="#/berichte">
                            Schülerberichte
                        </b-button> 
                        und sogar ein 
                        <b-button size="sm" 
                            variant="outline-primary" 
                            href="#/quiz">
                            Quiz
                        </b-button>
                        für euch erstellt.
                    </b-card>
                </b-col>

                <b-col>
                    <b-card class="m-1 shadow">
                        <template #header>
                            <h4 class="mb-0">Fragen</h4>
                        </template>
                        Wenn ihr Fragen habt, oder einfach ein bisschen 
                        mit uns über die Schule und den Unterricht
                        reden möchtet steht euch dafür unser
                        <b-button size="sm" variant="outline-primary" 
                            @click="$emit('open-discord')">
                            Discord-Server
                        </b-button>
                        zur Verfügung.<br>
                        Wir sind dort
                        <b-button size="sm" variant="outline-dark">
                            Montag bis Freitag von 15:00 bis 16:00 Uhr
                        </b-button>
                        und
                        <b-button size="sm" variant="outline-dark">
                            Samstag von 10:00 bis 12:00 Uhr
                        </b-button>
                        erreichbar.<br>
                        Ihr könnt Discord direkt über euren Browser
                        nutzen oder installieren. Eine Anleitung
                        zur Nutzung findet ihr 
                        <b-button size="sm" variant="outline-primary" href="#/discord">
                            hier
                        </b-button>.
                    </b-card>
                </b-col>

                <b-col>
                    <b-card class="m-1 shadow">
                        <template #header>
                            <h4 class="mb-0">Viel Spaß!</h4>
                        </template>
                        Schaut euch einfach um und meldet euch bei uns.<br>
                        Wir freuen uns über jede Gesellschaft und helfen euch gern weiter.
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { BContainer, BRow, BCol, BCard, BButton } from "bootstrap-vue"
export default {
    components: { BContainer, BRow, BCol, BCard, BButton },
    name: "Welcome"
}
</script>

<style scoped>
    .btn{
        padding-top:0;
        padding-bottom:0;
        margin:.1em;
    }
</style>